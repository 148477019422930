import Image, { type StaticImageData, type ImageProps } from "next/image"

type Props = {
  src: StaticImageData
  className?: string
  width?: ImageProps["width"]
  height?: ImageProps["height"]
  priority?: ImageProps["priority"]
  style?: ImageProps["style"]
}

export function Illustration({
  src,
  className,
  width,
  height,
  priority,
  style,
}: Props) {
  return (
    <Image
      src={src}
      className={className}
      width={width || src.width}
      height={height || src.height}
      priority={priority}
      // Alt is empty string because it's an illustration so it shouldn't be recognized by assistive technologies
      alt=""
      style={style}
    />
  )
}
