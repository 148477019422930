import React from "react"

import { DMSkeleton, normalizeTestId } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import Actionable from "../../Actionable/Actionable"
import type { ActionableRef } from "../../Actionable/Actionable.types"
import { VariantClasses } from "../../DMButtons/DMButton.types"
import DMIcon from "../../DMIcon/DMIcon"
import { DMLoader } from "../../DMLoader"

import type * as T from "./Button.types"

import { classNames, responsivePropDependency } from "reshaped/bundle"

function ButtonBase(props: T.DMButtonBaseProps, ref: ActionableRef) {
  const {
    isHighlighted,
    isFullWidth,
    isLoading,
    isSkeleton,
    isDisabled,
    type,
    href,
    size = "medium",
    className,
    variant = "solid",
    children,
    attributes,
    onClick,
    onPointerDown,
    isExternalLink,
    icon,
    endIcon,
    variantStyles,
    ariaLabel,
    onPointerUp,
    dataTestId,
    ...rest
  } = props

  const iconOnly = (icon || endIcon) && !children
  // Resolve classes base on the button size
  let widthClass = "w-form-medium"
  let heightClass = "h-form-medium"
  let iconGapClass = "gap-4"
  let fontClass = "text-body"
  let paddingClass = "px-12"
  let paddingIconSideClass = "pl-8"
  let paddingIconEndSideClass = "pr-8"

  switch (size) {
    case "small":
      widthClass = "w-form-small"
      heightClass = "h-form-small"
      fontClass = "text-body-small"
      paddingClass = "px-8"
      paddingIconSideClass = "pl-4"
      paddingIconEndSideClass = "pr-4"
      break
    case "large":
      widthClass = "w-form-large"
      heightClass = "h-form-large"
      iconGapClass = "gap-8"
      paddingClass = "px-16"
      paddingIconSideClass = "pl-12"
      paddingIconEndSideClass = "pr-12"
      break
  }

  // Resolve color classes based on the button variant (resulting to neutral styling)
  const defaultStyles: VariantClasses = {
    solid: {
      base: "bg-neutral text-neutral",
      hover: "hover:bg-neutral-highlighted",
      focus: "focus-visible:ring-2",
      highlighted: "bg-neutral-highlighted",
    },
    outline: {
      base: "bg-transparent text-neutral border-neutral-faded",
      hover: "hover:bg-neutral",
      focus: "focus-visible:ring-2",
      highlighted: "bg-neutral",
    },
    ghost: {
      base: "bg-transparent text-neutral ring-offset-0",
      hover: "hover:bg-neutral",
      focus: "focus-visible:ring-2",
      highlighted: "bg-neutral",
    },
    faded: {
      base: "bg-neutral-faded text-neutral",
      hover: "hover:bg-neutral",
      focus: "focus-visible:ring-2",
      highlighted: "bg-neutral",
    },
  }

  const variantClasses = variantStyles || defaultStyles[variant]

  const rootClassName = classNames(
    fontClass,
    "relative inline-flex items-center justify-center font-semibold rounded transition border ring-offset-1 max-w-full shrink-0",
    variantClasses?.base,
    variantClasses?.hover,
    variantClasses?.focus,
    iconGapClass,
    heightClass,
    iconOnly && !endIcon ? widthClass : paddingClass,
    iconOnly && endIcon && `${paddingIconEndSideClass} ${paddingIconSideClass}`,
    iconOnly && "shrink-0",
    icon && !iconOnly && paddingIconSideClass,
    endIcon && !iconOnly && paddingIconEndSideClass,
    variant !== "outline" && "border-[transparent]",
    isDisabled && "opacity-disabled",
    isFullWidth && "w-full text-center",
    isLoading && "pointer-events-none",
    isSkeleton && "[&>*]:invisible",
    isHighlighted && variantClasses?.highlighted,
    "active:scale-[0.98]",
    className
  )

  const renderIcon = (position: "start" | "end") => {
    const isStartValid = position === "start" && icon
    const isEndValid = position === "end" && endIcon
    const isInvalid = !(isStartValid || isEndValid)

    if (isInvalid) return null

    const iconClassName = classNames(
      "pointer-events-none",
      isLoading && "invisible"
    )

    const iconSize = responsivePropDependency(size, (size) => {
      if (size === "large") return "medium"
      return "small"
    })

    return (
      <DMIcon
        className={iconClassName}
        size={iconSize}
        svg={(position === "start" ? icon : endIcon)!}
      />
    )
  }

  const renderButtonContent = () => {
    return (
      <>
        {renderIcon("start")}
        {children ? (
          <span className={cn("truncate", { invisible: isLoading })}>
            {children}
          </span>
        ) : null}
        {renderIcon("end")}
      </>
    )
  }

  if (isSkeleton) {
    return (
      <div
        className={cn(
          rootClassName,
          "bg-elevation-base pointer-events-none border-none"
        )}
      >
        <div className="!visible absolute inset-0">
          <DMSkeleton width="100%" height="100%" />
        </div>
        {renderButtonContent()}
      </div>
    )
  }

  return (
    <Actionable
      ref={ref}
      ariaLabel={ariaLabel}
      attributes={attributes}
      className={cn(rootClassName)}
      href={href}
      isDisabled={isDisabled || isLoading}
      isExternalLink={isExternalLink}
      type={type}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      data-test-id={normalizeTestId(dataTestId)}
      {...rest}
    >
      <>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <DMLoader color="inherit" size="small" />
          </div>
        )}
        {renderButtonContent()}
      </>
    </Actionable>
  )
}

const Button = React.forwardRef(ButtonBase) as T.Export

export { Button }
